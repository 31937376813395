/* You can add global styles to this file, and also import other style files */
// for navigation bars
@import "../src/app/styles/colors.scss";
*{
  font-family: 'Noto Sans', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size:10px;
  @media screen and (min-width: 1240px) and (max-width: 1320px){
    font-size: 8px;
  }
  @media screen and (min-width: 1921px){
    font-size: calc(100vw * 0.0052);
  }
}
@font-face {
  font-family: "Noto Sans";
  src: url('./assets/fonts/noto_sans_regular.ttf');
}
strong{
  font-size: inherit;
}
em{
  font-size: inherit;
  word-wrap: break-word;
}
.swagger-ui *{
  font-size: inherit;
}
.swagger-ui .info .base-url {
  margin-top: 1rem !important;
}
.swagger-ui .info  hgroup.main a {
visibility: hidden;
}
.m-b-0{
  margin-bottom: 0!important;
}
.primary-nav {
  position: fixed;
  height: 7.2rem;
  width: 100%;
  top: 0;
  z-index: 99;
  border-bottom: .1rem solid $brand-secondary-gray;
}
.secondary-nav {
  position: fixed;
  top: 7.2rem;
  z-index: 3;
  border-right: .1rem solid $brand-secondary-gray;
  @media screen and (min-width:767px){
    position: sticky;
    top: 0;
  }
}

.mobile-sidetoggle-btn{
  @media screen and (min-width: 767px){
    display: none;
  }
}
.desktop-sidetoggle-btn{
  display: none;
  @media screen and (min-width: 767px){
    display: block;
  }
}
.logo-image-text-wrapper{
  display: flex;
}

// End of navigation bars style
.container {
  @media screen and (min-width: 1400px) {
    padding-right: 2rem;
    padding-left: 2rem;
  }
  @media screen and (min-width: 1921px) {
    max-width: 100%;
  }
}

.required-star {
  color: $brand-secondary-ruby;
  font-size: 1.4rem;
  line-height: 2.4rem;
  letter-spacing: 0.02em;
  font-weight: 500;
}
h2 {
  &.title {
    font-weight: 600;
    font-size: 1.8rem;
    line-height: 2.3rem;
    color: $brand-text-darkgrey;
    @media screen and (min-width: 1240px) and (max-width: 1320px) {
      font-size: 1.6rem;
      line-height: 2rem;
    }
  }
}
h3 {
  &.title {
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-weight: 400;
    letter-spacing: 0.02em;
    color: $brand-text-darkgrey;
    @media screen and (min-width: 1240px) and (max-width: 1320px) {
      
      line-height: 2.5rem;
    }
    &.bold {
      font-weight: 600;
      font-size: 1.8rem;
      line-height: 2.2rem;
      color: $brand-text-darkgrey;
      margin-top: .1rem;
      @media screen and (min-width: 1240px) and (max-width: 1320px) {
        font-size: 1.6rem;
      }
    }
  }
}
input.autoSuggest,
input.text-field, .tag-box-field,
textarea {
  width: 100%;
  border: .1rem solid $brand-subtle-grey;
  border-radius: 0.3rem;
  padding: 0.9rem 1.2rem;
  font-weight: 300;
  position: relative;
  font-size: 1.8rem;
  line-height: 2.8rem;
  outline: none;
  &:focus-within {
    border: .1rem solid $brand-active-darkcyan30;
    box-shadow: none;
  }
  ::-webkit-input-placeholder,
  :-ms-input-placeholder,
  ::placeholder,
  ::-moz-placeholder {
    color: $brand-subtle-grey;
  }

}
textarea{
  min-height: 8rem!important;
  &::-webkit-scrollbar{
    width: 0.6rem;
  }
  &::-webkit-scrollbar-track {
    background-color: $brand-secondary-gray;
  }
  &::-webkit-scrollbar-thumb{
    background-color:  $brand-border-lightgrey20;
    border-radius: 100px;
  }
}
input.errorActive, .tag-box-field.errorActive, textarea.errorActive{
  border: .1rem solid $brand-secondary-ruby;
  & ~ .error-text {
    display: flex;
    align-items: center;
    visibility: visible;
  }
}
input.disable{
  pointer-events: none!important;
}
.error-text {
  visibility: hidden;
  font-weight: 400;
  margin-top: 0.4rem;
  padding: 0 0.2rem;
  font-size: 1.4rem;
  line-height: 2.4rem;
  letter-spacing: 0.02em;
  color: $brand-secondary-ruby;
  margin-bottom: 0;
  span {
    margin-left: 0.7rem;
    font-size: inherit;
  }
  &.errorActive{
    display: flex;
    align-items: center;
    visibility: visible;
  }
  img{
    width: 1.6rem;
    height: 1.6rem;
  }
}
.hide-error .error-text{
  display: none;
}
.ds_tooltip {
  // display: inline-block;
  float: right;
  padding-left: 1.2rem;
  svg{

    width: 1.6rem;
    height: 1.6rem;
  }
  .tooltiptext {
    visibility: hidden;
    width: 23.4rem;
    border: .1rem solid $brand-secondary-gray;
    background-color: $brand-secondary-white;
    border-radius: 0.3rem;
    font-weight: 300;
    font-size: 1.6rem;
    line-height: 2rem;
    padding: 0.8rem 1.4rem 1.2rem;
    position: absolute;
    color: $brand-text-darkgrey;
    z-index: 1;
    top: 0;
    right: 0;
    box-shadow: 0 1rem 1.5rem -0.9rem $light-silver-gray;
    transform: translate(2.5rem, calc(-100% - 0.9rem));
    a{
      font-size: inherit;
    }
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      transform: translate(-50%, 99%);
      right: 0.5rem;
      border-width: 1.4rem;
      border-style: solid;
      border-color: $brand-secondary-white transparent transparent transparent;
    }

    @media (min-width: 1023px){
      transform: translate(47%, calc(-100% - 0.9rem));
      &::after{
        right: unset;
        left: 50%;
      }
    }
  }
  &:hover .tooltiptext {
    visibility: visible;
  }
  &:hover svg {
    fill: $brand-active-darkcyan30;
  }
}

.input-wrapper {
  width: auto;
  position: relative;
}
.up-dwn-arrow {
  position: absolute;
  height: 2.4rem;
  width: 2.7rem;
  background-image: url(/assets/textbox/up-down-arrow-textBox.svg);
  top: 50%;
  transform: translateY(-50%);
  right: 1.2rem;
  background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
}
.removeBtn {
  position: absolute;
  height: calc(100% - 0.2rem);
  width: 4.6rem;
  background-image: url(/assets/textbox/Remove-tags.svg);
  background-repeat: no-repeat;
  background-color: $brand-secondary-gray;
  top: 50%;
  transform: translateY(-50%);
  right: 0.1rem;
  border-radius: 0.3rem;
  cursor: pointer;
  background-size: contain;
}
.removeBtn-search {
  position: absolute;
  height: calc(100% - 0.2rem);
  width: 4.6rem;
  background-image: url(/assets/textbox/Remove-tags.svg);
  background-repeat: no-repeat;
  background-color: $brand-secondary-gray;
  top: 50%;
  transform: translateY(-50%);
  left: 15.5vw;
  border-radius: 0.3rem;
  cursor: pointer;
  background-size: contain;
}
.ds_c-tagsWrapper{
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}
.ds_c-tags {
  font-size: 1.2rem!important;
  line-height: 1.2rem;
  color: $brand-subtle-grey;
  font-weight: 600;
  padding: 0.6rem 0.8rem 0.6rem 1.2rem;
  background: $brand-secondary-gray;
  border: .1rem solid $brand-secondary-gray;
  border-radius: 2.4rem;
  word-break: break-all;
  // margin: .5rem;
  vertical-align: middle;
  // &:nth-of-type(1) {
  //   margin-left: 0;
  // }
  img{
    cursor: pointer;
    width: 1rem;
    height: 1rem;
  }
  &.review{
    padding: .7rem 1.2rem .7rem 1.2rem;
  }
}
.prod_card .ds_c-tags {
  padding: .4rem 1.1rem .4rem 1.1rem;
  background-color: $brand-secondary-white;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.5rem;
  color: $brand-text-darkgrey;
 
}

.flatBorder {
  border-radius: .3rem .3rem 0 0 !important;
  border: .1rem solid $brand-active-darkcyan30 !important;
}
.container {
  margin: 0 auto !important;
}
.form-section .container{
  padding-left: 2rem;
  padding-right: 2rem;
}
.ds_c-wrapper {
  display: flex;
  position: relative;
  background-color: $brand-secondary-lightgray;
  height: calc(100vh - 7.2rem);
  overflow: hidden;
  margin-top: 7.2rem;
  .body-content {
    width: 100%;
    overflow-y: auto;
    &::-webkit-scrollbar{
      width: 0.6rem;
    }
    &::-webkit-scrollbar-track {
      background-color: $brand-secondary-gray;
      // border-radius: 100px;
    }
    &::-webkit-scrollbar-thumb{
      background-color:  $brand-border-lightgrey20;
      border-radius: 100px;
    }
  }
  .content-section {
    display: flex;
    flex-wrap: wrap;
    gap: 0;
    @media screen and (min-width: 576px) {
      &.container{
        padding: 0 2.4rem;   
        max-width: 100%;   

      }
    }
    @media screen and (min-width: 767px) {
      padding: 0 2.4rem;
    }
    .form-section {
      width: 100%;
      background-color: $brand-secondary-white;
      @media screen and (min-width: 1023px) {
        width: calc(100% - 32.4rem);
      }
      @media screen and (min-width: 1800px) {
        width: calc(100% - 60.6rem);
      }
    }
    .info-section {
      width: 100%;
      padding-top: 2rem;
      p {
        margin-bottom: 2rem;
      }
      @media screen and (min-width: 1023px) {
        width: 30rem;
      }
      @media screen and (min-width: 1800px) {
        width: 58rem;
      }
    }
  }
}
.rel-comp-wrapper {
  position: relative;
}
.disable {
  pointer-events: none;
  h3.title {
    color: $brand-border-lightgrey20;
  }
  input.autoSuggest,
  input.text-field,
  textarea {
    border-color: $brand-border-lightgrey20;
    color: $brand-border-lightgrey20;
  }
  input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: $brand-border-lightgrey20;
}
input:-moz-placeholder,
textarea:-moz-placeholder {
  color: $brand-border-lightgrey20;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
  color: $brand-border-lightgrey20;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: $brand-border-lightgrey20;
}
}

.form-section {
  padding-top: 2.1rem;
  padding-bottom: 4.0rem;
  @media screen and (min-width: 1023px) {
    padding-bottom: 12.8rem;
  }
  .form-head {
    p {
      font-weight: 600;
      font-size: 1.4rem;
      line-height: 1.8rem;
      color: $brand-text-darkgrey;
      margin-bottom: 2rem;
    }
    margin-bottom: 2rem !important;
    border-bottom: .1rem solid $brand-secondary-gray;
  }
}
.button-wrapper {
  display: flex;
  gap: 2.4rem;
  // justify-content: end;
  flex-wrap: wrap;
}
form .button-wrapper{
  margin-top: 3rem;
}
.buttons-align-end{
  justify-content: flex-end;
}
.breadcrumbButton{
  .button-wrapper{
    gap: 1rem;
    @media screen and (min-width: 1023px) {
      gap: 2.4rem;      
    }
  }
}
footer{
  height: 4.8rem;
  position: relative;
}

.radio-wrapper{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  >div{
      flex: 1;
  }
}
// Tablist structure starts here

.ds_c-tablist{
  border-bottom: none;
  margin-bottom: 2.5rem;
  padding-left: 1.2rem;
  .nav-item{
    a{
      font-size: 1.4rem;
      line-height: 2rem;
      color: $brand-active-darkcyan30;
      font-weight: 600;
      padding: 1.1rem .8rem 1.6rem .8rem;
      border: none;
      border-bottom: .2rem solid $brand-secondary-gray;
      &.active{
       color: $brand-text-darkgrey;
        border-bottom: .4rem solid $brand-active-darkcyan30;
      }
      @media screen and (min-width: 767px) {
       font-size: 1.6rem; 
       padding: 1.1rem 0.9rem 1.6rem 0.9rem;
      }
    }
  }
  @media screen and (min-width: 767px) {
    padding-left: 2rem;
  }
}
.preSchema{
  overflow: auto ;
  min-height: 10rem;
  max-height: 45rem;
  pre{
    overflow: visible;
  }
  &::-webkit-scrollbar{
    width: 0.6rem;
    height: 0.6rem;
  }
  &::-webkit-scrollbar-track {
    background-color: $brand-secondary-gray;
  }
  &::-webkit-scrollbar-thumb{
    background-color:  $brand-border-lightgrey20;
    border-radius: 100px;
  }
}

// Table style
.ds_c-listing{
  &-table {
  // border-bottom: .1rem solid #e6e7e8;
  float: right;
  clear: right;
  width: 100%;
  overflow-x: auto;
  min-height: 30rem;
  &-filter{
    padding: 1.1rem 0;
    @media screen and (min-width: 1023px ) {
      display: flex;
      justify-content: space-between;
    }
   
    .navbar{
      display: flex;
      gap: 2.4rem;
      justify-content: flex-start;
      padding: 0;
      input.text-field{
        border-color: $brand-border-lightgrey20;
        padding-right: 5rem;
        font-weight: 300;
        width: 18vw;
      }
      .text-field-responsive{
        width: 17vw;
        line-height: 2.8rem;
        font-size: 1.8rem;
        outline: none;
         border: .1rem solid $brand-transparent-black-20;
         border-radius: 0.3rem;
         position: relative;
         padding: 0.9rem 1.2rem;
        &:focus-within {
          box-shadow: none;
        }
      }
      .removeBtn-search {
        position: absolute;
        height: calc(100% - 0.2rem);
        width: 2.5vw;
        background-image: url(/assets/textbox/Remove-tags.svg);
        background-repeat: no-repeat;
        background-color: $brand-secondary-gray;
        transform: translateY(-50%);
        left: 14.45vw;
        border-radius: 0.3rem;
        cursor: pointer;
        background-size: contain;
      }
      .multi-select{
        h3, .error-text{
          display: none;
        }
        .ds_c-multiSeltDrpdwn{
          width: 100%;
          @media screen and (min-width: 767px) {
            max-width: 38.5rem;
          }
          &-header{
            border-color: $brand-border-lightgrey20;
          }
        }
      }
    }
  }
  }
}
table{
  .action{
    img{
      width: 3.2rem;
      filter: invert(35%) sepia(6%) saturate(259%) hue-rotate(169deg) brightness(99%) contrast(93%);
      &:hover{
        filter: invert(25%) sepia(31%) saturate(1084%) hue-rotate(141deg) brightness(96%) contrast(102%);
      }
    }
    svg{
      width: 3.2rem;
      filter: invert(35%) sepia(6%) saturate(259%) hue-rotate(169deg) brightness(99%) contrast(93%);
      &:hover{
        filter: invert(25%) sepia(31%) saturate(1084%) hue-rotate(141deg) brightness(96%) contrast(102%);
      }
    }
  }

}

.search-icon{
  position: absolute;
  height: 2.4rem;
  width: 2.7rem;
  background-image: url(./assets/textbox/search-icon.svg);
  top: 50%;
  transform: translateY(-50%);
  right: 1.2rem;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
.ds_c-pagination{
  align-self: center;
  .ngx-pagination{
    padding: 0;
    margin-bottom: 0;
    text-align: right;
   
    li{
      color: $brand-active-darkcyan30;
      cursor: pointer;
      background-color: transparent;      
      text-decoration: none;
      border-bottom: solid .2rem $brand-secondary-gray;
      margin: 0;
      a{
        padding: .8rem 1.6rem;
        &:hover{
          background-color: transparent;
        }
      }
      &:hover{
        border-bottom: solid .4rem $brand-active-darkcyan30;
      }
      span{
        font-size: 1.6rem;
        line-height: 2rem;
      }
    }
    .current{
      color: $brand-text-darkgrey;
      border-bottom: solid .4rem $brand-active-darkcyan30;
      padding: .8rem 1.6rem;
      font-weight: 600;
    }

    .pagination-previous{
      border-bottom: none;
      &.disabled, a{
        cursor: auto;
        &::before{
          content: '';
          width: 1rem;
          height: 1.8rem;
          margin-right: 0;
          background-image: url('../src/assets/pagination/gray_left_arrow.svg');
          background-repeat: no-repeat;
          background-position: center;
          display: inline-block;
          vertical-align: middle;   
          background-size: contain;
            
        }
      }
      a{
        cursor: pointer;
        &::before{
          width: 3rem;
          height: 4rem;
          background-image: url('../src/assets/pagination/left_arrow.svg');
        }
      }
      &.disabled{
        padding: .8rem 1.6rem;
      }
      &:hover{
        border-bottom: none;
      }
    }
   
    .pagination-next{
      border-bottom: none;
      &.disabled, a{
        cursor: auto;
        padding-right: 0;
        &::after{
          content: '';
          width: 1rem;
          height: 1.8rem;
          margin-right: 0;
          background-image: url('../src/assets/pagination/gray_rgt_arrow.svg');
          background-repeat: no-repeat;
          background-position: center;
          display: inline-block;
          vertical-align: middle; 
          background-size: contain;         
        }
      }
      a{
        cursor: pointer;
        &::after{
          width: 3rem;
          height: 4rem;
          background-image: url('../src/assets/pagination/rgt_arrow.svg');
        }
      }
      &.disabled{
        padding: .8rem 1.6rem;
      }
      &:hover{
        border-bottom: none;
      }
    }
  }
}
.ds_c-renderCount{
  justify-content: space-between;
  display: flex;
  align-items: center;
  gap: 2rem;
  span{
    font-size: 1.5rem;
    line-height: 2rem;
  }
}
.pageControls, .footer-pageControls{
 display: flex;
 justify-content: flex-end;
//  gap: 2rem;
 flex-wrap: wrap;
 margin-top: 1rem;	
 @media screen and (min-width: 1023px) {	
  margin-top: 0;  	
 }
}
.footer-pageControls{
  margin-top: 2rem;
  margin-bottom: 4rem;
    .result{
      top: -1.5rem;
      transform: translateY(-100%);
    }
}
.attention-section{
  display: flex;
  margin-bottom: 1rem;
  // gap: 2rem;
  .image-heading{
    width: fit-content;
    padding-right: 2rem ;
    align-self: center;
    
    img{
      margin-bottom: 1rem;
    }
  }
  .content{
    padding-left: 2rem;
    border-left: .1rem solid $brand-secondary-gray;
    p{
      margin-bottom: 0;
    }
  }
}
.link_secondary{
  text-decoration: none;
  display: inline-block;
  vertical-align: top;
  font-size: 1.3rem;
  line-height: 1.6rem;
  font-weight: 400;
  font-family: "Noto Sans", Arial;
  text-align: center;
  border-radius: 0.3rem;
  padding: .7rem 1.1rem;
  letter-spacing: 0.02em;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
  transition: all 0.4s ease;
  transition-property: color, background, border-color;
  background: $brand-secondary-white;
    border: .1rem solid $brand-border-darkcyan30;
    color: $brand-primary-Teal;
    &:hover {
      background-color: $brand-primary-Teal;
      border: .1rem solid $brand-primary-Teal;
      color: $brand-secondary-white;
    }
}
form.loading{
  pointer-events: none;
}
.success_Icon, .unSuccess_Icon{
  display: flex;
  align-items: center;
  font-size: inherit;
  p{
    margin-bottom: 0;
    text-align: left;
    font-size: inherit;
  }
  img{
    filter: invert(0%) sepia(24%) saturate(38%) hue-rotate(13deg) brightness(93%) contrast(90%);
  }
}
.unSuccess_Icon img{
  filter: none;
  width: 3.2rem;
  @media screen and (min-width: 1240px) and (max-width: 1320px) {
    width: 2.2rem
  }
}
.warning_Icon{
  display: flex;
  align-items: center;
  font-size: inherit;
  p{
    margin-bottom: 0;
    text-align: left;
    font-size: inherit;
  }
  img{
    filter: invert(55%) sepia(32%) saturate(2379%) hue-rotate(360deg) brightness(102%) contrast(108%);;
    width: 3.2rem !important;
    height: 2rem;
    @media screen and (min-width: 1240px) and (max-width: 1320px) {
      width: 2.2rem
    }
  }
} 
.feedbackMsg{
  display: none;
  align-items: center;
  gap: .8rem;
  max-width: 33vw;
  span{
    margin-bottom: 0;
    text-align: right;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.76rem;
    word-wrap: break-word;
    white-space: pre-line;
    &.successColor{
      color: $brand-vibrant-green;
    }
    &.failureColor{
      color: $brand-secondary-ruby;
    }
  }
  img{
    filter: invert(47%) sepia(22%) saturate(3335%) hue-rotate(75deg) brightness(113%) contrast(67%);
    &.error{
      filter: none;
      width: 3.2rem;
    }
  }  
  &.feedbackActive{
    display: flex;
  }
}
form.disableClick{
  pointer-events: none;
  button.ds_c-button--{
      &primary{
      background-color: $brand-border-lightgrey20!important;
      border-color: $brand-border-lightgrey20!important;
      color: $brand-secondary-white;
    }
      &tertiary{
      background-color: $brand-border-lightgrey20!important;
      border-color: $brand-border-lightgrey20!important;
      color: $brand-secondary-white;
    }
  }
}
.inherit-text{
  font-size: inherit;
  display: flex;
  > *{
    font-size: inherit;
  }
}