/* Primary brand colors */

$brand-primary-Teal: #054E5A;

$brand-primary-gold: #E1B77E;

$brand-primary-gray1: #FFFFFF;
$brand-primary-gray2: #F6F6F7;
$brand-primary-gray3: #F6F6F7;
$brand-primary-gray7: #f8f8f8;
$brand-primary-gray8: #dae6ea;



/* Secondary brand colors */
$brand-secondary-peach: #f68d76;
$brand-secondary-orange: #f26522;
$brand-secondary-ruby: #c8102e;
$brand-secondary-violet: #6d2077;
$brand-secondary-arcticblue: #8dc8e8;
$brand-secondary-aquamarine: #e6ede6;
$brand-secondary-blue-gray:  #b4cace;
$brand-secondary-blue: #005470;
$brand-secondary-darkgreen05: #054E5A;
$brand-secondary-leaf: #43b02a;
$brand-secondary-petrol: #008c95;
$brand-secondary-gray: #F6F6F7;
$brand-secondary-lightgray: #f5f5f6;
$brand-secondary-white: white;

$brand-IT-accent-tan: #E1B77E;
$brand-VT-highlight-red: #C03627;
$brand-PT-accent-green: #4C9F70;
$brand-CT-pimary-navy: #123F6D;



$brand-primary-gray1: #FFFFFF;
$brand-primary-gray2: #F6F6F7;
$brand-primary-gray3: #F6F6F7;
$brand-primary-gray7: #f8f8f8;
$brand-primary-gray8: #dae6ea;

/* Gradient colors */
$brand-primary-darkblue10: #006c92;
$brand-primary-darkgreen05: #054E5A;
$brand-primary-lightgrey20: #CED9D7;
$brand-primary-lightblue15: #b3d7e3;

$brand-primary-yellow10: #e6b900;

// Card colors
$brand-card-yellow: #FFE3A5;
$brand-card-blue: #CCECFC;
$brand-card-aquamarine: #e6ede6;
$brand-card-gray-light: #E6E7E8;
$brand-card-gray: #93CAD0;
$brand-card-peach: #FCCFBF;






//colors that we are using
$pale-green-mint:#B3DBAE;
$soft-blue-gray: #BBBDC0;
$light-silver-gray: #c7c7c7;
$brand-body-color: #F5F5F6;
$brand-primary-white: #fafafb;
$brand-active-darkcyan30: #37717B;
$brand-text-darkgrey: #3E3F41;
$brand-border-lightgrey20: #B6BBBF;
$brand-border-darkcyan30:#37717B;
$brand-subtle-grey: #7D8083;
$brand-primary-text-hover-blue: #005671;
$cancel-button-hover-goldenrod: #D49848;
$brand-light-gray: #e6e6e6;
$brand-steel-gray: #939598;
$brand-pale-silver: #e5e5e5;
$brand-very-light-gray: #e7e7e7;
$brand-vibrant-green: #43B22A;
$brand-pearly-white-gray: #f7f7f7;
$brand-pure-white: #fff;
$brand-dark-grayish: #666666;

$brand-transparent-black-20: rgba(0, 0, 0, 0.2);
$brand-transparent-black-50: rgba(0,0,0,0.5);
$brand-semi-transparent-crimson: rgba(200,16,46,.2);
$brand-semi-transparent-white-45: rgba(255,255,255,0.45);

/* Text colors */
$brand-color-text: $brand-text-darkgrey;
$brand-color-headlines: $brand-active-darkcyan30;

// Border Color
$brand-border-gray: $brand-card-gray-light;

// === transition ===
$brand-tran-02: all 0.2s ease;
$brand-tran-03: all 0.3s ease;
$brand-tran-04: all 0.4s ease;
$brand-tran-05: width 0.5s ease;
$brand-transparent-light-gray:lightgray;
$brand-transparent-white:white;
